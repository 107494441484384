import React from 'react';
import { 
  BrowserRouter as Router, 
  Routes, 
  Route,
  useLocation
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { HelmetProvider } from 'react-helmet-async';
import { theme } from './theme';
import Landing from './pages/Landing';
import Solutions from './pages/Solutions';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { Box } from '@mui/material';
import Contact from './pages/Contact';
import About from './pages/About';
import TreePlanting from './pages/TreePlanting';
import TreeInsights from './pages/TreeInsights';
import CarbonCalculator from './pages/CarbonCalculator';
import InternalDashboard from './pages/InternalDashboard';
import LiveDashboard from './pages/LiveDashboard';
import LiveDashboardV2 from './pages/LiveDashboardV2';
import SDGPage from './pages/SDGPage';
import { AnimatePresence } from 'framer-motion';
import PageTransition from './components/PageTransition';

// Animated Routes Component
const AnimatedRoutes = () => {
  const location = useLocation();
  
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={
          <PageTransition>
            <Landing />
          </PageTransition>
        } />
        <Route path="/solutions" element={
          <PageTransition>
            <Solutions />
          </PageTransition>
        } />
        <Route path="/contact" element={
          <PageTransition>
            <Contact />
          </PageTransition>
        } />
        <Route path="/about" element={
          <PageTransition>
            <About />
          </PageTransition>
        } />
        <Route path="/plant-tree" element={
          <PageTransition>
            <TreePlanting />
          </PageTransition>
        } />
        <Route path="/tree-insights" element={
          <PageTransition>
            <TreeInsights />
          </PageTransition>
        } />
        <Route path="/carbon-calculator" element={
          <PageTransition>
            <CarbonCalculator />
          </PageTransition>
        } />
        <Route path="/internal" element={
          <PageTransition>
            <InternalDashboard />
          </PageTransition>
        } />
        <Route path="/live" element={
          <PageTransition>
            <LiveDashboard />
          </PageTransition>
        } />
        <Route path="/dashboard-v2" element={
          <PageTransition>
            <LiveDashboardV2 />
          </PageTransition>
        } />
        <Route path="/sdg" element={
          <PageTransition>
            <SDGPage />
          </PageTransition>
        } />
      </Routes>
    </AnimatePresence>
  );
};

function App() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            minHeight: '100vh'
          }}>
            <Navbar />
            <Box 
              component="main" 
              sx={{ 
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                pt: { xs: '56px', sm: '64px' }
              }}
            >
              <AnimatedRoutes />
            </Box>
            <Footer />
          </Box>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
