import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Grid, Paper, useTheme, Avatar, Chip, CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import ForestIcon from '@mui/icons-material/Forest';
import FavoriteIcon from '@mui/icons-material/Favorite';
import GroupsIcon from '@mui/icons-material/Groups';
import ParkIcon from '@mui/icons-material/Park';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import confetti from 'canvas-confetti';

const TREE_PLANTERS = [
  "Lorraine Chua", "Anam Nawaz Malik", "Rasha hamzeh", "Rebecca Gill", "Sara Raffaghello",
  "Malika Kennedy", "Alaa Thebian", "Aneesha Hingorani", "Jeda", "Khadija Quraishi",
  "Jen Stephenson", "Maricus", "Romain", "Christian loos", "Larissa", "Tala Mukaddam",
  "Salwa", "joe el lahham", "Anastasia", "Simon Ashwin", "Ali zein", "Christopher Fernando",
  "Seham", "Anthony Mchayleh", "Neha", "Ashfaq", "Denit Varghese", "Simin", "Fayaz",
  "Elie Raad", "Tonia Eid", "Clare Fearon", "Samer khansa", "Elie", "Ali Rez",
  "Rizek jildeh", "Rana hamarneh", "Nameer Abou Ismail", "Santadip Roy", "Shantelle Nagarajan",
  "Barry kirsch", "Lara", "Ingrid dsouza", "Carl chalhoub", "Raksha Khimji",
  "Ahmed Aborady", "Marisa lawson", "Georges Naaman", "Zoya Abu Jamra", "Hash Milhan",
  "Khaled", "Nadine", "Ahmed", "Sandy hanna", "Thomas Otton", "Ashrit", "Hiba Faisal",
  "Alex Jevons", "Ishwari", "Nadine essinawi", "Nadeem", "George's oneissy", "Veronica Nipay",
  "Haytham Lamah", "Anup Oommen", "Dimitrios Pappos", "Laila", "Stephanie mukherjee"
];

const TOTAL_TREES = 68;
const CO2_PER_TREE = 22; // kg CO2 per tree per year

const TreeInsights = () => {
  const theme = useTheme();
  const [treePlanters, setTreePlanters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eventStats, setEventStats] = useState({
    totalPlanters: 0,
    treesPlanted: 0,
    totalImpact: 0
  });

  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      try {
        if (mounted) {
          // Use the predefined list of planters
          const plantersData = TREE_PLANTERS.map(name => ({
            name,
            created_at: new Date().toISOString() // All planted at the same time
          }));

          setTreePlanters(plantersData);
          setEventStats({
            totalPlanters: plantersData.length,
            treesPlanted: TOTAL_TREES,
            totalImpact: TOTAL_TREES * CO2_PER_TREE
          });
          setLoading(false);
          
          // Multiple confetti bursts
          const colors = ['#2E7D32', '#4CAF50', '#81C784'];
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 },
            colors
          });
          
          // Second burst after a delay
          setTimeout(() => {
            confetti({
              particleCount: 50,
              angle: 60,
              spread: 55,
              origin: { x: 0, y: 0.6 },
              colors
            });
            confetti({
              particleCount: 50,
              angle: 120,
              spread: 55,
              origin: { x: 1, y: 0.6 },
              colors
            });
          }, 500);
        }
      } catch (error) {
        console.error('Error setting up data:', error);
        if (mounted) setLoading(false);
      }
    };

    fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box sx={{ minHeight: '100vh', py: 4 }}>
        {/* Hero Section with Map Pin */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
            <LocationOnIcon sx={{ fontSize: 40, color: theme.palette.primary.main, mr: 1 }} />
            <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
              Eden Reforestation Projects, Madagascar
            </Typography>
          </Box>
          <Typography 
            variant="h2" 
            component="h1" 
            gutterBottom 
            sx={{ 
              textAlign: 'center',
              mb: 2,
              background: 'linear-gradient(45deg, #2196F3 30%, #4CAF50 90%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontWeight: 'bold'
            }}
          >
            Tree Planting Champions
          </Typography>
          <Typography 
            variant="h5" 
            sx={{ 
              textAlign: 'center',
              mb: 6,
              color: theme.palette.text.secondary,
              maxWidth: '800px',
              mx: 'auto'
            }}
          >
            Together, we're restoring Madagascar's precious mangrove forests! Each tree planted creates 
            a lasting impact on both the environment and local communities.
          </Typography>
        </motion.div>

        {/* Event Stats */}
        <Grid container spacing={3} sx={{ mb: 6 }}>
          {[
            {
              icon: <GroupsIcon sx={{ fontSize: 40 }} />,
              value: eventStats.totalPlanters,
              label: 'Amazing Tree Planters',
              suffix: '',
              description: 'Coming together for Madagascar'
            },
            {
              icon: <ParkIcon sx={{ fontSize: 40 }} />,
              value: eventStats.totalImpact,
              label: 'kg CO₂ Captured per Year',
              suffix: '',
              description: 'Making a real difference'
            }
          ].map((stat, index) => (
            <Grid item xs={12} md={6} key={index}>
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: index * 0.1 }}
              >
                <Paper
                  elevation={3}
                  sx={{
                    p: 4,
                    textAlign: 'center',
                    height: '100%',
                    background: 'linear-gradient(135deg, #1976d2 0%, #2196F3 50%, #81c784 100%)',
                    color: 'white'
                  }}
                >
                  {stat.icon}
                  <Typography variant="h2" sx={{ my: 2, fontWeight: 'bold' }}>
                    {stat.value.toLocaleString()}{stat.suffix}
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                    {stat.label}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ opacity: 0.9 }}>
                    {stat.description}
                  </Typography>
                </Paper>
              </motion.div>
            </Grid>
          ))}
        </Grid>

        {/* Location Impact with Enhanced Styling */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
        >
          <Paper 
            elevation={3} 
            sx={{ 
              p: 4, 
              mb: 6,
              background: 'linear-gradient(45deg, rgba(33, 150, 243, 0.05) 30%, rgba(76, 175, 80, 0.05) 90%)',
              borderRadius: 4,
              position: 'relative',
              overflow: 'hidden'
            }}
          >
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <Typography variant="h4" gutterBottom color="primary.main" sx={{ fontWeight: 'bold' }}>
                  Your Impact in Madagascar
                </Typography>
                <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem' }}>
                  Your trees will be planted in the coastal regions of Madagascar, where they'll help restore 
                  vital mangrove ecosystems. These mangroves are crucial for:
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {[
                    'Protecting coastal communities from erosion and storms',
                    'Providing breeding grounds for marine life',
                    'Creating jobs for local communities',
                    'Supporting endangered species unique to Madagascar',
                    'Fighting climate change through carbon capture'
                  ].map((impact, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.5 + index * 0.1 }}
                    >
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 2,
                        p: 1,
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: 'rgba(76, 175, 80, 0.1)'
                        }
                      }}>
                        <ForestIcon color="primary" />
                        <Typography sx={{ fontSize: '1.1rem' }}>{impact}</Typography>
                      </Box>
                    </motion.div>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  transition={{ duration: 0.3 }}
                >
                  <Box
                    component="img"
                    src="https://images.unsplash.com/photo-1584974292709-5c2f0619971b?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
                    alt="Madagascar Mangroves"
                    sx={{
                      width: '100%',
                      height: 'auto',
                      borderRadius: 4,
                      boxShadow: theme.shadows[10]
                    }}
                  />
                </motion.div>
              </Grid>
            </Grid>
          </Paper>
        </motion.div>

        {/* Champions Gallery with Enhanced Cards */}
        <Box sx={{ mb: 6, textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
            Our Tree Planting Heroes
          </Typography>
          <Typography variant="h6" sx={{ mb: 4, color: theme.palette.text.secondary }}>
            Meet the amazing people who are making Madagascar greener
          </Typography>
          
          <Grid container spacing={3}>
            {treePlanters.map((planter, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  whileHover={{ 
                    scale: 1.03,
                    transition: { duration: 0.2 }
                  }}
                >
                  <Paper 
                    elevation={2}
                    sx={{ 
                      p: 3,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 2,
                      position: 'relative',
                      overflow: 'hidden',
                      background: '#ffffff',
                      border: '1px solid',
                      borderColor: 'primary.light',
                      borderRadius: 3,
                      transition: 'transform 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-8px)'
                      }
                    }}
                  >
                    <Avatar 
                      sx={{ 
                        width: 80, 
                        height: 80, 
                        bgcolor: theme.palette.primary.main,
                        fontSize: '2rem',
                        border: '3px solid',
                        borderColor: theme.palette.primary.light
                      }}
                    >
                      {planter.name.charAt(0).toUpperCase()}
                    </Avatar>
                    
                    <Typography variant="h6" sx={{ textAlign: 'center' }}>
                      {planter.name}
                    </Typography>
                    
                    <Chip 
                      icon={<FavoriteIcon />}
                      label="Tree Planter"
                      color="primary"
                      sx={{ mt: 1 }}
                    />

                    <Box 
                      sx={{ 
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        transform: 'rotate(45deg)',
                        opacity: 0.1
                      }}
                    >
                      <ForestIcon sx={{ fontSize: 100 }} />
                    </Box>
                  </Paper>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Enhanced Inspiring Message */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1 }}
        >
          <Paper 
            elevation={3} 
            sx={{ 
              p: 6, 
              mt: 6, 
              textAlign: 'center',
              background: 'linear-gradient(45deg, rgba(33, 150, 243, 0.1) 30%, rgba(76, 175, 80, 0.1) 90%)',
              borderRadius: 4
            }}
          >
            <ForestIcon sx={{ fontSize: 60, color: theme.palette.primary.main, mb: 2 }} />
            <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
              A Legacy of Hope
            </Typography>
            <Typography variant="h6" color="text.secondary" sx={{ maxWidth: '800px', mx: 'auto', lineHeight: 1.8 }}>
              Each tree planted in Madagascar is more than just a number - it's a symbol of hope, 
              a source of life, and a gift to future generations. Together, we're creating a greener, 
              more sustainable world, one mangrove at a time.
            </Typography>
          </Paper>
        </motion.div>
      </Box>
    </Container>
  );
};

export default TreeInsights;
